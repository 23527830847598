<template>
  <div>
    <div v-if="existing">
      <div class="d-flex">
        <v-icon class="">mdi-camera-outline</v-icon>
        <div class="d-flex align-center w-full pl-3">
          <v-label>{{ label }}</v-label>
          <v-btn
            small
            color="danger"
            class="float-end mx-5"
            @click="$emit('input', null)"
            ><v-icon color="white">mdi-close</v-icon></v-btn
          >
        </div>
      </div>
      <div class="thumbnail-container pl-3">
        <v-img
          class="preview-image"
          :src="thumbnailSrc"
          @click="thumbnailDialog = true"
        ></v-img>
      </div>
    </div>
    <v-row v-else class="py-0">
      <v-col cols="12" class="py-0">
        <v-file-input
          v-model="file"
          :label="label"
          accept="image/*"
          prepend-icon="mdi-camera-outline"
          truncate-length="10"
          :rules="rules"
          outlined
        ></v-file-input>
        <Lightbox v-model="thumbnailDialog" :src="src"></Lightbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Lightbox from "@/components/Lightbox";
import moment from "moment";

export default {
  name: "ImageUpload",
  components: { Lightbox },
  props: {
    value: {
      default: "",
    },

    label: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    storageDir: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      file: null,
      thumbnailDialog: false,
    };
  },
  methods: {},
  computed: {
    src() {
      const dir = this.storageDir ? this.storageDir : "file";

      return `${process.env.VUE_APP_API_URL}/storage/${dir}/${this.value}`;
    },
    thumbnailSrc() {
      return `${process.env.VUE_APP_API_URL}/commands/thumbnail?resource=${this.value}&height=50&width=50&force=true&storageDir=${this.storageDir}`;
    },
    existing() {
      return this.value && typeof this.value === "string";
    },
    dateFormatted() {
      if (!this.dateField) {
        return null;
      }
      return moment(this.dateField, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
  },
  watch: {
    file(value) {
      console.log("changed", value);
      this.$emit("input", value);
    },
  },
};
</script>
<style lang="scss">
.thumbnail-container {
  width: 250px;
}

.thumbnail-label {
  width: 100px;
}

.preview-image {
  margin: 15px 0px 30px 20px;
  max-height: 200px;
  max-width: 250px;
}
</style>
