<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6">Course</v-card-title>
        <div class="pa-5">
          <v-form ref="form">
            <div class="d-flex align-center mb-7">
              <v-icon class="pr-2">mdi-folder-zip-outline</v-icon>
              <fieldset class="dropzone-fieldset">
                <legend class="dropzone-label">Upload Scorm File</legend>
                <vue-dropzone
                  ref="formDropzone"
                  id="scormDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-sending="sendingEvent"
                  @vdropzone-complete="fetchScorm"
                  @vdropzone-error="errorEvent"
                  @vdropzone-success="successEvent"
                ></vue-dropzone>
              </fieldset>
            </div>
            <v-text-field
              prepend-icon="mdi-numeric"
              v-model="scorm.upload_version"
              label="Upload Version"
              outlined
              disabled
              readonly
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-text"
              v-model="scorm.title"
              :disabled="getScormLoading"
              :rules="[(v) => !!v || 'Title is required']"
              label="Title"
              outlined
            ></v-text-field>
            <v-col cols="12" class="pa-0">
              <image-upload
                label="Thumbnail"
                v-model="scorm.thumbnail"
                :rules="rules"
              ></image-upload>
            </v-col>
            <v-text-field
              prepend-icon="mdi-numeric"
              type="number"
              :rules="[
                (v) => !!v || 'Max course try is required',
                (v) => v > 0 || 'Max course try must be more than 0',
              ]"
              v-model="scorm.max_course_try"
              label="Max Course Try"
              outlined
              required
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-numeric"
              type="number"
              :rules="[
                (v) => !!v || 'Passing score is required',
                (v) => v > 0 || 'Passing score must be more than 0',
              ]"
              v-model="scorm.passing_score"
              label="Passing score"
              outlined
              required
            ></v-text-field>
            <v-select
              label="Language"
              prepend-icon="mdi-format-list-bulleted-square"
              :rules="[(v) => !!v || 'Language is required']"
              v-model="scorm.language"
              :items="languages"
              item-title="text"
              item-value="value"
              required
              outlined
            ></v-select>
            <v-text-field
              prepend-icon="mdi-cash"
              prepend-inner-icon="mdi-currency-eur"
              label="Price per student"
              type="number"
              min="0"
              v-model="scorm.price_per_student"
              :rules="[(v) => !!v || 'This is required']"
              outlined
            />
            <v-text-field
              prepend-icon="mdi-percent"
              type="number"
              class="pr-1"
              label="VAT"
              min="0"
              :rules="[(v) => !!v || 'This is required']"
              v-model="scorm.vat"
              outlined
            />
            <v-col cols="12" class="pa-0">
              <image-upload
                label="Signup Logo"
                v-model="scorm.sign_up_logo"
              ></image-upload>
            </v-col>
            <v-text-field
              v-model="scorm.sign_up_title"
              prepend-icon="mdi-text"
              label="Signup Title"
              outlined
            />
            <v-text-field
              prepend-icon="mdi-form-textbox-password"
              label="Signup Password"
              type="password"
              v-model="scorm.sign_up_password"
              outlined
            />
            <v-text-field
              prepend-icon="mdi-text"
              label="Signup Mail Subject"
              v-model="scorm.sign_up_mail_subject"
              outlined
            />
            <v-select
              prepend-icon="mdi-format-list-bulleted-square"
              label="Signup Notification"
              :items="notifications"
              item-text="label"
              item-value="value"
              :rules="[(v) => !!v || 'This is required']"
              v-model="scorm.notification_id"
              outlined
            ></v-select>
            <v-textarea
              prepend-icon="mdi-text"
              label="Extra Signup Check"
              :rules="[(v) => !!v || 'This is required']"
              v-model="scorm.extra_sign_up_check"
              rows="1"
              outlined
              required
            ></v-textarea>
            <div class="d-flex mb-8">
              <v-icon class="pr-2">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Terms of Use</div>

                <HtmlEditor
                  v-model="scorm.terms_of_use"
                  placeholder="Write something..."
                  :className="'w-100 mt-2'"
                ></HtmlEditor>
              </div>
            </div>
            <div class="d-flex mb-8">
              <v-icon class="pr-2">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Privacy Policy</div>

                <HtmlEditor
                  v-model="scorm.privacy_policy"
                  placeholder="Write something..."
                  :className="'w-100 mt-2'"
                ></HtmlEditor>
              </div>
            </div>
            <v-select
              prepend-icon="mdi-format-list-bulleted-square"
              outlined
              label="Certificate"
              v-model="scorm.certificate_id"
              :items="getCertificates"
              :rules="[(v) => !!v || 'This is required']"
              item-text="name"
              item-value="id"
            ></v-select>
            <v-text-field
              prepend-icon="mdi-text"
              label="Course Name on Certificate"
              :rules="[(v) => !!v || 'This is required']"
              v-model="scorm.course_name_on_certificate"
              outlined
              required
            />
            <div class="d-flex mb-8">
              <v-icon class="pr-2">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">Certificate Email</div>

                <HtmlEditor
                  v-model="scorm.certificate_email"
                  placeholder="Write something..."
                  :className="'w-100 mt-2'"
                ></HtmlEditor>
              </div>
            </div>
            <v-text-field
              prepend-icon="mdi-text"
              label="Certificate Email BCC"
              v-model="scorm.certificate_email_bcc"
              outlined
            />
            <v-select
              prepend-icon="mdi-format-list-bulleted-square"
              label="Certificate Expire Year"
              v-model="scorm.certificate_expire_year"
              :items="expireYears"
              item-title="text"
              item-value="value"
              outlined
            ></v-select>
            <v-text-field
              prepend-icon="mdi-text"
              v-model="scorm.export_email"
              :disabled="getScormLoading"
              label="Export Email"
              outlined
            ></v-text-field>

            <div class="d-flex mb-8">
              <v-icon class="pr-2">mdi-text</v-icon>
              <div class="w-full pl-1">
                <div class="mb-3 label grey--text">
                  Payment Invoice Mail Body
                </div>
                <HtmlEditor
                  v-model="scorm.payment_invoice_mail_body"
                  placeholder="Write something..."
                  className="w-100 mt-2"
                ></HtmlEditor>
              </div>
            </div>

            <v-row class="px-5">
              <v-col
                v-for="column in switches"
                :key="column.db"
                cols="12"
                md="6"
              >
                <v-switch
                  class="pa-0 ma-0"
                  inset
                  v-model="scorm[column.db]"
                  :label="column.text"
                  :true-value="1"
                  :false-value="0"
                  color="primary"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row class="pt-5" justify="end">
              <v-btn color="primary" :disabled="getScormLoading" @click="save"
                >Edit Course
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import HtmlEditor from "@/components/HtmlEditor";
import ImageUpload from "@/components/ImageUpload.vue";
import { CERTIFICATE_EXPIRE_YEARS, LANGUAGES } from "@/shared/constants";
import { convertObjectToFormData } from "@/shared/utils";
import store from "@/store";
import { get } from "lodash";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ImageUpload, HtmlEditor, vueDropzone: vue2Dropzone },
  metaInfo: {
    title: "Edit Scorm Course",
  },
  data() {
    return {
      id: get(this.$route, "params.id"),
      languages: LANGUAGES,
      expireYears: CERTIFICATE_EXPIRE_YEARS,
      uploadedFile: null,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/scorm/update-version`,
        acceptedFiles: ".zip",
        headers: { Authorization: `Bearer ${store.getters.getToken}` },
        uploadMultiple: false,
        chunking: true,
        chunkSize: 5242880,
      },
      rules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 2000000 ||
            "Image size should be less than 2 MB!"
          );
        },
      ],
      switches: [
        { text: "Active", db: "active" },
        { text: "Has Student ID", db: "has_student_id" },
        { text: "Payment", db: "payment" },
        { text: "Show Function Name", db: "show_function_name" },
        { text: "Show Birthdate", db: "show_birthdate" },
        {
          text: "Display franchise partner name",
          db: "display_franchise_partner_name",
        },
      ],
    };
  },

  async mounted() {
    this.getNotifications.length
      ? this.getNotifications
      : await this.setNotifications();
  },

  computed: {
    ...mapGetters([
      "getCourseLookup",
      "getCertificates",
      "getScorm",
      "getScormLoading",
      "getNotifications",
    ]),

    scorm() {
      return this.getScorm;
    },

    notifications() {
      const selections = [];

      this.getNotifications.forEach((item) => {
        selections.push({
          label: item.key,
          value: item.id,
        });
      });

      return selections;
    },
  },

  methods: {
    ...mapActions([
      "editScorm",
      "setScorm",
      "setScormLoading",
      "setNotifications",
    ]),

    sendingEvent(file, xhr, formData) {
      formData.append("fileUuid", get(this.scorm, "scorm_uuid"));
      formData.append("scoUuid", get(this.scorm, "sco_uuid"));
      this.$store.dispatch("setScormLoading", true);
    },

    errorEvent() {
      this.$store.dispatch("showSnackbar", "Error on updating scorm version.");
      this.$store.dispatch("setScormLoading", false);
    },

    successEvent() {
      this.$store.dispatch("showSnackbar", "Scorm file version updated.");
    },

    async save() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");

        return;
      }

      try {
        let formData = convertObjectToFormData(this.scorm);
        this.editScorm(formData);

        this.$store.dispatch("showSnackbar", "Course edit success.");
        this.$router.push({ name: "course-view" });
      } catch (error) {
        console.error("DEBUG: error", error);
      }
    },

    fetchScorm() {
      try {
        this.$refs.formDropzone.removeAllFiles();
        this.$store.dispatch("setScorm", {
          id: this.id,
        });
      } catch (error) {
        console.error("DEBUG: error", error);
        this.$store.dispatch("setScormLoading", false);
      }
    },
  },
};
</script>
<style>
.label {
  font-size: 16px;
  padding-left: 8px;
}

.dropzone-fieldset {
  width: 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.4);
  border-width: thin;

  .dropzone-label {
    font-size: 13px;
    margin-left: 8px !important;
    padding: 0 3px;
  }
}

#scormDropzone {
  background: none;
  border: none;
  padding: 0;
  min-height: 0;

  .dz-message {
    margin: 0;
    padding-top: 10px;
    height: 50px;
  }

  .dz-preview {
    height: 150px;
  }

  .dz-details {
    border-radius: 4px;
  }

  .dz-progress {
    margin-top: 25px !important;
  }
}
</style>
